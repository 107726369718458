<template>
  <div>
    <head-layout
      head-title="通知人员"
      :head-btn-options="headBtn"
      @head-add-tabs="headAddTabs"
      @head-remove-tabs="headRemoveTabs"
    />
    <grid-head-layout ref="searchForm" :search-columns="searchColumns"
                      @grid-head-search="searchChange" @grid-head-empty="searchReset"/>
    <!--    <grid-layout-->
    <!--      ref="gridLayOut"-->
    <!--      :table-options="tableOptions"-->
    <!--      :table-data="tableData"-->
    <!--      :table-loading="tableLoading"-->
    <!--      :data-total="page.total"-->
    <!--      :page="page"-->
    <!--      @page-current-change="handleCurrentChange"-->
    <!--      @page-size-change="handleSizeChange"-->
    <!--      @page-refresh-change="onLoadExamUser"-->
    <!--      @gird-handle-select-click="selectionChange"-->
    <!--    ></grid-layout>-->
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      @page-refresh-change="onLoadExamUser"
      @gird-handle-select-click="selectionChange"
    ></grid-layout>

    <el-dialog
      :title="$t('cip.plat.sys.user.field.roleConfig')"
      append-to-body
      :visible.sync="roleBox"
      width="90%"
      top="10vh"
    >
      <departmentPersonnel ref="departmentPersonnel" v-if="roleBox"
                           :disabled-list="disabledErpItemList"></departmentPersonnel>
      <span slot="footer" class="dialog-footer">
          <el-button @click="roleBox = false">{{
              $t("cip.cmn.btn.celBtn")
            }}</el-button>
        </span>
      <span slot="footer" class="dialog-footer">
          <el-button @click="submitBox()">
              确定
            </el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>

import {mapGetters} from "vuex";

import FormLayout from "@/views/components/layout/form-layout";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import {getLineList, eduexamuser, removeLine} from "@/api/training/eduexamuser";
import departmentPersonnel from "@/views/business/training/exam/departmentPersonnel";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import draft from "@/views/plugin/workflow/mixins/draft";
import {getList} from "@/api/system/user";

import exampaperDialog from "@/views/business/training/exam/exampaperDialog";
import {
  gettrainingEducationSetDetilList,
  trainingEducationSetDetilRemoveLine,
  trainingEducationSetHeadSubmit
} from "@/api/training/reEducationSetting";

export default {
  name: "eduexamEdit",
  mixins: [exForm, draft],
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  components: {
    departmentPersonnel,
    FormLayout,
    HeadLayout,
    GridLayout,
    exampaperDialog,
  },
  data() {
    return {
      roleBox: false,
      tableData: [],
      tableLoading: false,
      disabledErpItemList: [],
      dataForm: {
        id: "",
      },
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      searchColumns: [
        {
          label: '',
          prop: "userName",
          span: 4,
          placeholder: "请输入用户姓名",
        },
        {
          label: '',
          prop: "deptName",
          span: 4,
          placeholder: "请输入所属组织",
        },
      ],
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    ids() {
      let ids = [];
      let selectionList = this.$refs.gridLayOut.selectionList
      selectionList.forEach(ele => {
        if (ele.id) {
          ids.push(ele.id);
        }
      });
      return ids.join(",");
    },
    tableOptions() {
      return {
        menu: false,
        index: true,
        indexLabel: "序号",
        column: [
          {
            label: "用户姓名",
            prop: "userName",
            width: 400,
            align: 'left',
            overHidden: true,
          },
          {
            label: "组织",
            prop: "deptName",
            align: 'left',
            overHidden: true,
          }
        ]
      }
    },
    headBtn() {
      let buttonBtn = [];
      if (this.type == 'edit' || this.type == 'add' || this.type == 'notice') {
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.addBtn`),
            emit: "head-add-tabs",
            type: "button",
            btnOptType: 'primary',
          });
        buttonBtn.push(
          {
            label: this.$t(`cip.cmn.btn.delBtn`),
            emit: "head-remove-tabs",
            type: "button",
            btnOptType: 'remove',
          });
        return buttonBtn;
      }
      return buttonBtn;
    }
  },
  methods: {
    selectionChange(list) {
      this.selectionList = list;
    },
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.page.currentPage = 1
      this.onLoadExamUser(this.page, this.searchColumns)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoadExamUser(this.page, this.searchColumns)
    },
    searchReset() {
      this.query = {};
      this.onLoadExamUser(this.page)
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoadExamUser(this.page, params)
    },
    onLoadExamUser(page, params = {}, userRelationList, id) {
      this.loading = true;
      this.handleSelectedValue = [];
      params.educationId = this.dataForm.id
      gettrainingEducationSetDetilList(
        1
        , 999,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.loading = false;
        this.disabledErpItemList = this.tableData.map(e => e.userId + "" + e.deptId);
        if (typeof userRelationList != "undefined" && userRelationList != "") {
          this.trainingNotificationPersonnel(userRelationList, id)
        }
      });

      getList(
        {
          ...params, ...this.query,
          current: page.currentPage,
          size: page.pageSize,
          deptId: this.treeDeptId,
        }
      ).then((res) => {
        const data = res.data.data;
        this.examuserData = data.records;
        this.loading = false;
      });
    },
    reception(date) {
      this.dataForm.id = date;
    },
    headAddTabs() {
      console.log("this.dataForm.id", this.dataForm.id)
      if (this.dataForm.id == "") {
        return this.$message.error("请先保存头表信息");
      }
      this.roleBox = true
    },
    /**
     * 如果已经添加则不允许在显示人员
     * @param userRelationList
     * @param id
     */
    trainingNotificationPersonnel(userRelationList, id) {

      if (typeof id == "undefined") {
        var confirm = userRelationList;
        let repeatingData = []
        confirm.forEach(e => {
          let cc = {
            "userName": e.userName,
            "deptId": e.deptId,
            "deptName": e.deptName,
            "userId": e.userId,
          }
          repeatingData.push(cc)
        })
        this.tableData = repeatingData
      } else {
        var confirmTheSubmission = userRelationList;
        let repeatingData = []
        confirmTheSubmission.forEach(e => {
          let cc = {
            "userName": e.userName,
            "deptId": e.deptId,
            "deptName": e.deptName,
            "userId": e.userId,
            "educationId": id
          }
          repeatingData.push(cc)
        })
        const uniqueItems = this.findAndStoreUniqueItems(repeatingData, this.tableData)
        //只添加过滤后的人员
        if (uniqueItems.length > 0) {
          uniqueItems.forEach(e => {
            let cc = {
              "userName": e.userName,
              "deptId": e.deptId,
              "deptName": e.deptName,
              "userId": e.userId,
              "educationId": id
            }
            this.tableData.push(cc)
          })
          this.disabledErpItemList = this.tableData.map(e => e.userId + "" + e.deptId);
          let form = {
            "id": this.dataForm.id,
            "methodOfPreservation": "notifyingOfficer",
            "trainingEducationSetDetils": uniqueItems
          }
          this.submitQuery(form)
        }
      }
    },
    findAndStoreUniqueItems(list1, list2) {
      const uniqueItems = [];
      for (const item1 of list1) {
        // 检查 item1 是否在 list2 中存在
        const existsInList2 = list2.some(item2 => this.isEqual(item1, item2));
        if (!existsInList2) {
          uniqueItems.push(item1);
        }
      }
      return uniqueItems;
    },
    isEqual(obj1, obj2) {
      return obj1.deptId === obj2.deptId && obj1.userId === obj2.userId;
    },
    submitBox() {

      // eduexamuser(form).then(res => {
      //   this.onLoad(this.page);
      //   this.$message({
      //     type: "success",
      //     message: "操作成功!"
      //   });
      //   this.roleBox = false
      // })
      var confirmTheSubmission = this.$refs.departmentPersonnel.confirmTheSubmission();
      let repeatingData = []
      confirmTheSubmission.forEach(e => {
        let cc = {
          "userName": e.realName,
          "deptId": e.deptId,
          "deptName": e.deptName,
          "userId": e.userId,
          "educationId": this.dataForm.id
        }
        repeatingData.push(cc)
      })
      this.tableData = this.tableData.concat(repeatingData)
      this.disabledErpItemList = this.tableData.map(e => e.userId + "" + e.deptId);
      // eduexamuser(this.tableData)
      //   .then(() => {
      //     this.$message({
      //       type: "success",
      //       message: this.$t('cip.cmn.msg.success.operateSuccess')
      //     });
      //     this.onLoadExamUser(this.page);
      //   })
      //只添加选择的人员
      let form = {
        "id": this.dataForm.id,
        "methodOfPreservation": "notifyingOfficer",
        "trainingEducationSetDetils": repeatingData
      }
      this.submitQuery(form)
      this.roleBox = false
    },
    submitQuery(form) {
      trainingEducationSetHeadSubmit(form).then(res => {
        this.onLoadExamUser({
          pageSize: 20,
          currentPage: 1
        }, {educationId: form.id}, "", "")
        return this.$message({
          type: "success",
          message: "操作成功！",
        });
      })
    },
    information() {
      console.log("进行输出---tableDatatableDatatableData------", this.tableData)
      return this.tableData
    },
    headRemoveTabs() {

      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
        return;
      }
      this.$confirm(this.$t(`cip.cmn.msg.warning.determineDelWarning`), {
        confirmButtonText: this.$t(`cip.cmn.btn.defBtn`),
        cancelButtonText: this.$t(`cip.cmn.btn.celBtn`),
        type: "warning"
      })
        .then(() => {
          if (this.ids.length > 0) {
            this.tableLoading = true;
            trainingEducationSetDetilRemoveLine(this.ids)
              .then(() => {
                let query = {}
                this.$message({
                  type: "success",
                  message: this.$t('cip.cmn.msg.success.operateSuccess')
                });
                this.$refs.gridLayOut.selectionClear()
                this.onLoadExamUser(this.page);
              });
            this.tableLoading = false
          } else {
            let indexes = this.selectionList.filter(e => e.id == null)
            for (let i = this.tableData.length - 1; i >= 0; i--) {
              let item = this.tableData[i]
              if (indexes.filter(e => e.deptId === item.deptId && e.userId === item.userId).length > 0) {
                this.tableData.splice(i, 1)
                this.disabledErpItemList = this.tableData.map(e => e.userId + "" + e.deptId);
              }
            }
            this.$refs.gridLayOut.selectionClear()
          }
        })
    },
  },
  mounted() {
    this.onLoadExamUser(this.page, params)
  },
  created() {
  }
}
</script>

<style scoped lang="scss">

</style>
